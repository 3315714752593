import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponentComponent } from "app/pages/base/base-component/base-component.component";
import { TranslateService } from "@ngx-translate/core";
import { PublicService } from "app/services/public.service";
import { Dialogs } from "app/common/dialogs";
import { FormGroup, FormBuilder } from '@angular/forms';
import swal from 'sweetalert2';
import { Subscription } from "rxjs";
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: ".content_inner_wrapper",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})

export class LoginComponent extends BaseComponentComponent implements OnDestroy {


  private subscription: Subscription;
  public loading: boolean = false;
  public emailRecover: string;
  public toggleRegister: boolean = false;
  public isDesktop;

  public formData: FormGroup;


  constructor(public translate: TranslateService, private publicService: PublicService, private router: Router, private dialogs: Dialogs, private _formBuilder: FormBuilder, private deviceService: DeviceDetectorService) {
    super();
    this.onCreateForm();
    this.isDesktop = this.deviceService.isDesktop();
  }

  onCreateForm() {
    this.formData = this._formBuilder.group(
      {
        email: "",
        password: "",        
      }
    );
  }

  onSubmit(formData) {
    this.setLoading(true);
    const email = formData.email;
    const password = formData.password;
    this.subscription = this.publicService.login(email, password)
      .subscribe(
      data => {
        // console.log("data.isExtrasAvailable",data.isExtrasAvailable.toString());
        
        if(data.actiu == "0"){
          this.dialogs.showError(super.getMsgDialogs("_login_error"), "Usuario no activo, contacte con su responsable.");
          return;
        }
        this.setLoading(false);
        this.publicService.setAccessToken(data.token);
        this.publicService.setName(data.name);
        this.publicService.setEMail(data.email);
        this.publicService.setClaim(data.claim);
        this.publicService.setDBName(data.dbname);
        this.publicService.setDBUser(data.dbuser);
        this.publicService.setDBPass(data.dbpass);
        this.publicService.setUserId(data.id);
        this.publicService.setOperatiu(data.operatiu);
        this.publicService.setCodiOperariG2(data.codiOperariG2);
        this.publicService.setUserDefaultTipus(data.tipusCatDefaultId);
        this.publicService.setNumParte(data.numParte);
        this.publicService.setHour(data.horari);
        this.publicService.setTypeCat(data.tipusCat); 
        this.publicService.setOnlyControl(data.onlyControl); 
        this.publicService.setPreuArticle(data.preuArticle);
        this.publicService.setPreuConcept(data.preuConcept);
        this.publicService.setDiesLimite(data.diesLimite);
        this.publicService.setHorasParte(data.horasParte);
        this.publicService.setMostraPartes(data.mostraPartes);
        this.publicService.setMostraControl(data.mostraControl);
        this.publicService.setMostraTasques(data.mostraTasques);
        this.publicService.setColor(data.color);
        this.publicService.setFullsOperVinculats(data.fullsOperVinculats); 
        this.publicService.setCompanyId(data.companyId);
        this.publicService.setPrioritatAuto(data.prioritatTaskAuto);
        
        if(data.claim == "std" && data.mostraPartes == 0 && data.mostraControl == 0){
          this.dialogs.showError(super.getMsgDialogs("_login_error"), "Su usuario no tiene permisos para acceder a la aplicación.","");
          return;
        }
        
          this.router.navigate(['/','alerts']);
          return;    

      },
      error => {
        console.log(error);

        this.setLoading(false);
        if (error.statusText == "Bad Request") {
          this.dialogs.showError(super.getMsgDialogs("_login_error"), "Usuario o contraseña incorrectos!");
        }
        else if (error.statusText == "Empresa deshabilitada") {
          this.dialogs.showError(super.getMsgDialogs("_login_error"), "Empresa deshabilitada, contacte con G2Software");
        } else {
          this.dialogs.showError(super.getMsgDialogs("_login_error"), "");
        }
      }
      );
  }

  closeLeftSideBar(){
      $(".app_sidebar-left-open").removeClass("app_sidebar-left-open");
      $(".appBackdrop").removeClass("appBackdrop");
  }

  private setLoading(loading: boolean): void {
    this.loading = loading;
  }

  public isEmptyText() {
    return this.emailRecover == "" ? true : false;
  }

  public onStartRecoveryPassword() {
    const sub = this.publicService.StartResotrePassword(this.emailRecover)
      .subscribe(
      data => {
        // console.log(data);
        this.dialogs.showItemUploaded("Email enviat", this.getString("CHECK_EMAIL"), "", false).then(
          function () {
            document.getElementById("btnModal").click();
          }, function (dissmis) { }
        )
      },
      error => {
        if (error.statusText=="Unknown Error") {
          this.dialogs.showItemUploaded("E-mail enviat!", "");
        } else {
          this.dialogs.showError("Error en enviar el email", error.statusText);
        }
      },
      () => { }
      );

    if (this.subscription != undefined) {
      this.subscription.add(sub);
    } else {
      this.subscription = sub;
    }
  }

  resendEmail(){
    var miau = this.emailRecover;
    this.publicService.getToken(this.emailRecover).subscribe(
      data => {
          this.resendNewEmail(this.emailRecover,data);
      },
      error => {       
      }
    );
  }
  resendNewEmail(data,token) {
    this.publicService.resendPassword(data,token).subscribe(
      data => {
        this.dialogs.showItemUploaded("Recuperar contraseña", "Se ha enviado el email de recuperación correctamente.", "/");
      },
      error => {
        this.dialogs.showError("Error", "Ha habido un error al enviar el email de recuperación.");
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }

}
