import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';

import { BaseAuthService } from "./base-auth.service";
import { PublicService } from "./public.service";

import { Page } from "./models/page";
import { PagedData } from "./models/paged-data";
import { ServerResponse } from "./models/server-response.model";
import { PageProject } from "app/services/models/page-project";

@Injectable()
export class DataService<T> extends BaseAuthService {

    private data: ServerResponse<T>;
    public dataUpdated = new EventEmitter<PagedData<T>>();

    constructor(public publicService: PublicService, private httpClient: HttpClient) {
        super(publicService);
    }
 
    public getData(method: string, filter: string, filterUserId: string, filterDateFrom, filterDateTo, filterDateContractEnd, limit: number, offset: number, 
        dir: string, orderCol: string, ignoreCurrentUser: boolean, isActive: string, isPlanificable: string, isOcult: string, businessUnit: string, 
        filterNoRev: boolean, filterNoSync: boolean, filterFull: string, filterState: number, filterPrior: number, filterNoOper: boolean, filterAveria: boolean, filterInstal: boolean, filterPress: boolean, filterGen: boolean, filterVaca: boolean, filterMant: boolean, filterCliId: string,  filterAbierto: boolean, filterCurso: boolean, filterEspera: boolean, filterCerrado: boolean, filterValidado: boolean): Observable<ServerResponse<T>> {
        //console.log("getdata", filterDateContractEnd);
        var url = this.getBasePath() + method + "/";
        if(!limit){ limit = 25;}

        let params = new HttpParams()
            .append('filter', filter)
            .append('filterUserId', filterUserId == undefined ? "" : filterUserId)
            .append('filterDateFrom', filterDateFrom == undefined ? "" : filterDateFrom)
            .append('filterDateTo', filterDateTo == undefined ? "" : filterDateTo)
            .append('dir', dir)
            .append('orderCol', orderCol)
            .append('limit', limit.toString())
            .append('offset', offset.toString())
            .append('isActive', isActive)            
            .append('ignoreCurrentUser', ignoreCurrentUser ? 'true' : 'false');

        var dbname = this.publicService.getDBName();
        var dbuser = this.publicService.getDBUser();
        var dbpass = this.publicService.getDBPass();
        var userId = this.publicService.getUserId();
        
        filterNoRev = (filterNoRev == undefined)? false : filterNoRev;
        filterNoSync = (filterNoSync == undefined)? false : filterNoSync;
        filterNoOper = (filterNoOper == undefined)? false : filterNoOper;
        filterAveria = (filterAveria == undefined)? false : filterAveria;
        filterInstal = (filterInstal == undefined)? false : filterInstal;
        filterPress = (filterPress == undefined)? false : filterPress;
        filterGen = (filterGen == undefined)? false : filterGen;
        filterVaca = (filterVaca == undefined)? false : filterVaca;
        filterMant = (filterMant == undefined)? false : filterMant;
        filterAbierto = (filterAbierto == undefined)? false : filterAbierto;
        filterCurso = (filterCurso == undefined)? false : filterCurso;
        filterEspera = (filterEspera == undefined)? false : filterEspera;
        filterCerrado = (filterCerrado == undefined)? false : filterCerrado;
        filterValidado = (filterValidado == undefined)? false : filterValidado;


        filterFull = (filterFull == undefined)? "" : filterFull;
        filterCliId = (filterCliId == undefined)? "" : filterCliId;

        if(filterDateFrom == undefined || filterDateTo == undefined){
            filterDateFrom = "";
            filterDateTo = "";
        }

        if (method == "getAllTasks"){
            if(this.publicService.isAdmin()){
                var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","userid":"'+filterUserId+'", "dataI":"'+filterDateFrom+'","dataF":"'+filterDateTo+'","state":"'+filterState+'","priority":"'+filterPrior+'","fullId":"'+filterFull+'","clientId":"'+filterCliId+'","filterNoOper":"'+filterNoOper+'","filterAveria":"'+filterAveria+'","filterInstal":"'+filterInstal+'","filterPress":"'+filterPress+'","filterGen":"'+filterGen+'","filterVaca":"'+filterVaca+'","filterMant":"'+filterMant+'","filterAbierto":"'+filterAbierto+'","filterCurso":"'+filterCurso+'","filterEspera":"'+filterEspera+'","filterCerrado":"'+filterCerrado+'","filterValidado":"'+filterValidado+'"}');
            }
            else{
                var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","userid":"'+filterUserId+'", "dataI":"'+filterDateFrom+'","dataF":"'+filterDateTo+'","state":"'+filterState+'","priority":"'+filterPrior+'","fullId":"'+filterFull+'","clientId":"'+filterCliId+'","filterNoOper":"'+filterNoOper+'","filterAveria":"'+filterAveria+'","filterInstal":"'+filterInstal+'","filterPress":"'+filterPress+'","filterGen":"'+filterGen+'","filterVaca":"'+filterVaca+'","filterMant":"'+filterMant+'","filterAbierto":"'+filterAbierto+'","filterCurso":"'+filterCurso+'","filterEspera":"'+filterEspera+'","filterCerrado":"'+filterCerrado+'","filterValidado":"'+filterValidado+'"}');
            }
        }
        else{
           if(this.publicService.isAdmin()){
                if(filterUserId == undefined){
                    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","userid":"0","dataI":"'+filterDateFrom+'","dataF":"'+filterDateTo+'","NoSync":"'+filterNoSync+'","NoRev":"'+filterNoRev+'","fullId":"'+filterFull+'","orderCol":"'+orderCol+'","dir":"'+dir+'"}');
                }else{
                    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","userid":"'+filterUserId+'","dataI":"'+filterDateFrom+'","dataF":"'+filterDateTo+'","NoSync":"'+filterNoSync+'","NoRev":"'+filterNoRev+'","fullId":"'+filterFull+'","orderCol":"'+orderCol+'","dir":"'+dir+'"}');
                }
            }
            if(this.publicService.isStandard()){
                var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","userid":"'+userId+'","dataI":"'+filterDateFrom+'","dataF":"'+filterDateTo+'","NoSync":"'+filterNoSync+'","NoRev":"'+filterNoRev+'","fullId":"'+filterFull+'","orderCol":"'+orderCol+'","dir":"'+dir+'"}');
            }     
        }
             

        return this.httpClient.post<any>(url, body/*{ headers: this.getHeaders(), params: params, body: body}*/);
    }

    public getResults(method: string, page: PageProject): void {
        if (page.allData) {            
            this.getData(method, page.filter, page.filterUserId, page.filterDateStartFrom, page.filterDateStartTo, page.filterDateContractEnd, page.totalElements, 
                0, page.sortDir, page.sortColumn, page.ignoreCurrentUser, page.isActive, page.isPlanificable, page.isOcult, page.businessUnit, 
                page.filterNoRev, page.filterNoSync, page.filterFull, page.filterState, page.filterPrior, page.filterNoOper, page.filterAveria, page.filterInstal, page.filterPress, page.filterGen, page.filterVaca, page.filterMant, page.filterCliId, page.filterAbierto, page.filterCurso, page.filterEspera, page.filterCerrado, page.filterValidado)
                .subscribe(
                data => {
                    this.data = data;    
                    this.dataUpdated.emit(this.getPagedData(page));
                },
                error => {}
                );
        } else {
            this.getData(method, page.filter, page.filterUserId, page.filterDateStartFrom, page.filterDateStartTo, page.filterDateContractEnd, page.size, 
                page.pageNumber * page.size, page.sortDir, page.sortColumn, page.ignoreCurrentUser, page.isActive, page.isPlanificable, page.isOcult, page.businessUnit, 
                page.filterNoRev, page.filterNoSync, page.filterFull, page.filterState, page.filterPrior, page.filterNoOper, page.filterAveria, page.filterInstal, page.filterPress, page.filterGen, page.filterVaca, page.filterMant, page.filterCliId, page.filterAbierto, page.filterCurso, page.filterEspera, page.filterCerrado, page.filterValidado)
                .subscribe(
                data => {
                    this.data = data;
                    //if(this.data.totalRows == undefined &&! this.data[0]){}else{
                         this.dataUpdated.emit(this.getPagedData(page));
                    //}
                   
                },
                error => { }
                );
        }
    }

    private getPagedData(page: PageProject): PagedData<T> {
        let pagedData = new PagedData<T>();
        if (this.data != null) {
            page.pageNumber = page.pageNumber;
            page.size = this.data.limit;
            this.data.totalRows = Object.keys(this.data).length;
            page.totalElements = this.data.totalRows;
            page.totalPages = page.totalElements / page.size;
            pagedData.data = this.data.values;
            pagedData.miau = this.data;
        }
        pagedData.page = page;
        return pagedData;
    }
    public delete(method: string, id: string) {
        var url = this.getBasePath() + method + "/" + id;
        return this.httpClient.delete(url, { headers: this.getHeaders(), responseType: 'text' });
    }

}
