import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { PublicService } from "../../../../services/public.service";
import { Dialogs } from '../../../../common/dialogs';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private publicService: PublicService, private router: Router, private dialogs:Dialogs) { }

  ngOnInit() {
    
    this.publicService.logout();
    this.router.navigate(['/login']);
    //location.reload();
  }

}