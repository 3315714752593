import { Page } from "app/services/models/page";

export class PageProject extends Page{
    filterDateStartFrom:string;
    filterDateStartTo: string;
    filterDateContractEnd: string;
    filterActive:boolean;
    filterUserId:string;
    ignoreCurrentUser:boolean;
    isActive:string;
    isPlanificable:string;
    isOcult:string;
    businessUnit:string;
    filterNoRev: boolean;
    filterNoSync: boolean;
    filterFull: string;
    filterState: number;
    filterPrior: number;
    filterNoOper: boolean;
    filterAveria: boolean;
    filterInstal: boolean;
    filterPress: boolean;
    filterGen: boolean;
    filterVaca: boolean;
    filterMant: boolean;
    filterCliId: string;
    filterAbierto: boolean;
    filterCurso: boolean;
    filterEspera: boolean;
    filterCerrado: boolean;
    filterValidado: boolean;
}