import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import swal from 'sweetalert2';
import { Location } from "@angular/common";


@Injectable()
export class Dialogs {

	constructor(private router: Router, private location: Location) { }

	showWarning(title: string, message: string, route?: string) {
        let self =this
        return swal.fire({
            title: title,
            text: message,
            icon: 'warning',
            confirmButtonText: 'Ok'
        }).then(
            function () {
                if (route != "" && route != undefined) {
                    if (route == "reload") {
                        location.reload();
                    } else {
                        self.router.navigate([route]);
                    }
                }else {
					self.location.back();
				}
            }
            );
	}

	showWarningHere(title: string, message: string, route?: string) {
        let self =this
        return swal.fire({
            title: title,
            text: message,
            icon: 'warning',
            confirmButtonText: 'Ok'
        }).then(
            function () {
                if (route != "" && route != undefined) {
                    if (route == "reload") {
                        location.reload();
                    } else {
                        self.router.navigate([route]);
                    }
     			}
            }
            );
	}


	showInfo(title: string, message: string, route?: string) {
        let self =this
        return swal.fire({
            title: title,
            text: message,
            icon: 'info',
            confirmButtonText: 'Ok'
        }).then(
            function () {
                if (route != "" && route != undefined) {
                    if (route == "reload") {
                        location.reload();
                    } else {
                        self.router.navigate([route]);
                    }
     			}
            }
            );
	}



    showError(title: string, message: string, route?: string) {
        let self =this
        return swal.fire({
            title: title,
            text: message,
            icon: 'error',
            confirmButtonText: 'Ok'
        }).then(
            function () {
                if (route != "" && route != undefined) {
                    if (route == "reload") {
                        location.reload();
                    } else {
                        self.router.navigate([route]);
                    }
                }
            }
            );
	}

	showAskDelete(title: string, text: string) {
		let self = this;
		return swal.fire({
			title: title,
			text: text,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#f44336',
			cancelButtonColor: '#999999',
			confirmButtonText: 'Sí',
			cancelButtonText: 'No'
        });
	}

	showAsk(title: string, text: string) {
		let self = this;
		return swal.fire({
			title: title,
			text: text,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
  			//cancelButtonColor: '#d33',
			cancelButtonColor: '#999999',
			confirmButtonText: 'Sí',
			cancelButtonText: 'No'
        });
	}

	showCancel(route: string, show: boolean, back?: boolean) {
		let self = this;
		if (show) {
			swal.fire({
				title: 'Los cambios se perderan',
				text: "¿Seguro que quiere perder los cambios?",
				icon: 'warning',
				showCancelButton: true,
				//confirmButtonColor: '#3085d6',
				cancelButtonColor: '#999999',
				confirmButtonText: 'Sí',
				cancelButtonText: 'No'
			}).then(
				function (value) {
					if (!value.isDismissed)
						self.router.navigate([route]);
				}
				);
		} else {
			if (back)
				self.location.back();
			else
				self.router.navigate([route]);
		}
	}

	showItemUploaded(title: string, message: string, route?: string, back?: boolean) {
		let self = this;
		return swal.fire({
			title: title,
			text: message,
			icon: 'success',
			confirmButtonText: 'Ok',
			allowOutsideClick: false
		}).then(
			function () {
                if (route != "" && route != undefined) {
                    if (route == "reload") {
                        location.reload();
                    } else {
                        self.router.navigate([route]);
                    }
				} else {
					if (back)
						self.location.back();
				}
			}
			);
	}

	showItemUploadedLog(title: string, message: string, route?: string, back?: boolean) {
		let self = this;
		return swal.fire({
			title: title,
			text: message,
			icon: 'success',
			confirmButtonText: 'Ok',
			allowOutsideClick: false
		}).then(
			function () {

                window.location.href = "https://g2partes.g2soft.com";
                //self.router.navigate([route]);
            }
		);
	}

	showItemDeleted(title: string, message: string): Promise<any> {
		return swal.fire({
			title: title,
			text: message,
			icon: 'success',
			confirmButtonText: 'Ok'
		});
	}

}